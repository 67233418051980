import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';
import { Blog } from './blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {


  public errorMessage: any = {};
  public successMessage: any = {};
  httpOptions: { headers: HttpHeaders; };

  constructor(
    private myService: ConfigurationService,
    private httpService: HttpClient
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
      })
    };

  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      console.error('An error occurred:', error.error);
      this.errorMessage.message = error.error.error_description;
    } else if (error.status === 400) {
      console.error('An error occurred:', error.error);
      this.errorMessage.message = error.error.error_description;
    } else if (error.status === 403) {
      console.error('An error occurred:', error.error);
      this.errorMessage.message = error.error.error_description;
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  public getAllBlog(postJson: any): Observable<Blog[]> | any {
    let params = new HttpParams();
    //params = params.append('role_id', '4');
    for (var key in postJson[0]) {
      if (postJson[key] !== undefined) {
        params = params.append(key, postJson[key]);
      }
    }
    this.httpOptions['params'] = params;
    return this.httpService.get<Blog[]>(`${this.myService.constant.apiUrl}/blog`, this.httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

  public getBlogBySlug(blogId: any): Observable<Blog[]> | any {
    return this.httpService.get<Blog[]>(`${this.myService.constant.apiUrl}/blog/${blogId}`, this.httpOptions)
      .pipe(
        retry(3),
        catchError(this.handleError)
      );
  }

}
